import React, {ChangeEvent} from 'react'
import Nav from 'react-bootstrap/Nav'
import Link from 'next/link'
import {FaShoppingCart} from 'react-icons/fa'
import Overlay from 'react-overlays/Overlay'
import {useDispatch, useSelector} from 'react-redux'
import Router from 'next/router'
import Dinero, {Currency} from 'dinero.js'
import Skeleton from 'react-loading-skeleton'
import classNames from 'classnames'
import {thumbUrl} from '../utils/image'
import styles from '../styles/cart_nav.module.scss'
import CartQuantitySelect from './cart/quantity_select'
import {actions} from '../store/actions'
import cartStyles from '../styles/cart_item.module.scss'
import {formatDinero, useAppCurrency} from '../service/currency_converter'
import {ChecklistForCartOrQuotationItems, getCartItemPrice} from '../lib/cart_item'
import {useRegionCountryCode} from '../hooks/region'
import Api from "../service/api";
import TimeoutAlert from './timeout_alert';
import {RootState} from "../store/reducers/reducers";
import * as gtag from "../lib/gtag";
import VariantInfo from './variant_info';
import useCartAndQuotationItems from "../hooks/use_cart_and_quotation_items";
import {QuotationItem, QuotationItemSources, QuotationItemStatuses} from "../types/models/quotation_item";
import {
  getQuotationItemPrice,
  getWrappedItemName,
  getWrappedItemSourceLabel,
  getWrappedItemVariantInfo,
  isQuotationItemDisabledInCart
} from "../lib/quotation_item";
import QuotationItemStatusBadgeComponent from "./cart/quotation_item_status_badge";
import {OverlayTrigger, Tooltip} from "react-bootstrap";
import {FiAlertCircle} from "react-icons/fi";

export default function CartNav() {
  const [itemsLocked, setItemsLocked] = React.useState(false);
  const [checkState, setCheckState] = React.useState<ChecklistForCartOrQuotationItems>(new ChecklistForCartOrQuotationItems());
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
  const [show, setShow] = React.useState(false)
  const overlayTimeout = React.useRef<number | null>();
  const analyticsEventSent = React.useRef(false);

  const triggerRef = React.useRef(null);
  const containerRef = React.useRef(null);

  const cartData = useCartAndQuotationItems();
  const cartSetItemError = useSelector((state: RootState) => state.cart.setItemError);

  const dispatch = useDispatch();
  const appCurrency = useAppCurrency();
  const regionInfo = useRegionCountryCode();

  React.useEffect(() => {
    dispatch(actions.cart.fetchCart());
    dispatch(actions.quotationItem.fetchQuotationItems());
    const intervalId = setInterval(() => {
      if (overlayTimeout.current && Date.now() > overlayTimeout.current) {
        setShow(false)
      }
    }, 75);

    return () => clearInterval(intervalId);
  }, []);

  React.useEffect(() => {
    setItemsLocked(false);

    setCheckState(checkState.setItems(cartData.items));
  }, [cartData.items]);

  React.useEffect(() => {
    if (cartSetItemError) {
      setErrorMessage(cartSetItemError)
      dispatch(actions.cart.setSetItemError(null))
    }
  }, [cartSetItemError]);

  React.useEffect(() => {
    if (!show || !cartData.isLoaded || analyticsEventSent.current || regionInfo.isLoading) {
      return;
    }

    gtag.viewCart(cartData.items, regionInfo.countryCode);
    analyticsEventSent.current = true;
  }, [cartData.isLoaded, regionInfo.countryCode, regionInfo.isLoading, show]);


  const handleMouseEnter = React.useCallback(() => {
    setShow(true);
    overlayTimeout.current = null;
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    overlayTimeout.current = Date.now() + 150
  }, [])

  const handleCloseOverlay = React.useCallback(() => {
    setShow(false)
    overlayTimeout.current = null
  }, [])

  const lockItems = React.useCallback(() => {
    setItemsLocked(true)
  }, [])

  const handleRemoveItem = React.useCallback((index: number, shouldSendEvent = true) => () => {
    if (window.confirm('Are you sure?')) {
      const item = cartData.items[index];
      if (shouldSendEvent) {
        gtag.removeFromCart(item, regionInfo.countryCode);
      }
      if (item.isQuotation) {
        dispatch(actions.quotationItem.removeItemAsync(item.item._id));
      } else {
        dispatch(actions.cart.removeItemAsync(item.item._id));
      }
    }
  }, [cartData, regionInfo.countryCode])

  const handleSetQuantity = React.useCallback((index: number) => (currentQuantity: number) => {
    const wrappedItem = cartData.items[index];
    if (wrappedItem.item.quantity === currentQuantity) {
      return
    }

    if (currentQuantity === 0) {
      handleRemoveItem(index)()
    } else {
      if (wrappedItem.isQuotation) {
        dispatch(actions.quotationItem.setItemQuantityAsync(currentQuantity, wrappedItem.item._id));
      } else {
        dispatch(actions.cart.setItemQuantityAsync(currentQuantity, wrappedItem.item._id));
      }
      lockItems()
    }
  }, [cartData.items, handleRemoveItem, lockItems])

  const handleCartItemCheck = React.useCallback((isQuotation: boolean, itemId: string) => (e: ChangeEvent<HTMLInputElement>) => {
    setCheckState(checkState.setCheck(isQuotation, itemId, e.target.checked));
  }, [checkState])

  const handleCheckout = React.useCallback(() => {
    // when checking out copy lock the quantity, variation and product
    // get checked items
    const cartItemIds: string[] = [];
    const quotationItemIds: string[] = [];
    for (const wrappedItem of cartData.items) {
      if (checkState.getCheck(wrappedItem.isQuotation, wrappedItem.item._id)) {
        if (wrappedItem.isQuotation) {
          quotationItemIds.push(wrappedItem.item._id);
        } else {
          cartItemIds.push(wrappedItem.item._id);
        }
      }
    }

    if (quotationItemIds.length === 0  && cartItemIds.length === 0) {
      return
    }

    Api.checkout.createSession(cartItemIds, quotationItemIds)
      .then((res) => {
        const {checkoutSessionKey} = res.data
        Router.push('/checkout?stateKey=' + checkoutSessionKey)
      })
      .catch((e) => {
        console.error('failed to create checkout session')
        console.error(e)
      })
  }, [checkState, cartData.items]);

  let subtotal = React.useMemo(() => {
    if (!appCurrency || regionInfo.isLoading || !cartData.isLoaded) {
      return 'Calculating';
    }

    let subtotal = Dinero({
      amount: 0,
      currency: appCurrency as Currency
    });

    for (const wrappedItem of cartData.items) {
      if (!checkState.getCheck(wrappedItem.isQuotation, wrappedItem.item._id)) {
        continue;
      }

      if (wrappedItem.isQuotation) {
        if (wrappedItem.item.status === QuotationItemStatuses.resolved) {
          const itemPrice = getQuotationItemPrice(wrappedItem.item, regionInfo.countryCode, wrappedItem.product);
          subtotal = subtotal.add(itemPrice);
        }
      } else {
        const itemPrice = getCartItemPrice(wrappedItem.item, wrappedItem.product, regionInfo.countryCode);
        subtotal = subtotal.add(itemPrice);
      }
    }

    return formatDinero(subtotal);
  }, [cartData.items, checkState, appCurrency, regionInfo.countryCode, regionInfo.isLoading])

  const checkoutDisabled = checkState.checkedCount() === 0;

  return <Nav.Item className="mr-2 d-none d-md-block" ref={containerRef}>
    <Link href="/cart">
      <a
        className="nav-link"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={triggerRef}
      >
        <div className={styles.cartIcon}>
          {cartData.items.length > 0 && (
            <div className={styles.cartIcon_count}>
              {cartData.items.length.toString()}
            </div>
          )}
          <FaShoppingCart size={24} />
        </div>
      </a>
    </Link>

    <Overlay
      show={show}
      rootClose
      onHide={handleCloseOverlay}
      offset={[0, 10]}
      placement="bottom"
      container={containerRef}
      target={triggerRef}
    >
      {({ props }) => {
        return <div {...props} className={styles.card_container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className={`card ${styles.card}`}>
            <div className={`card-header font-weight-bold ${styles.card_header}`}>
              My Cart
            </div>

            <TimeoutAlert
              message={errorMessage}
              onHide={() => setErrorMessage(null)}
            />

            <div className={`card-body ${styles.card_body}`}>
              {cartData.items.length === 0 ? (
                <p>Your Cart is empty.</p>
              ) : (
                cartData.items.map((wrappedItem, index) => {
                  const {isQuotation} = wrappedItem;

                  let imageUrl: string;
                  if (isQuotation) {
                    imageUrl = wrappedItem.item.mainImage;
                    if (wrappedItem.item.productSource === QuotationItemSources.internal) {
                      imageUrl = thumbUrl(imageUrl);
                    }
                  } else {
                    imageUrl = thumbUrl(wrappedItem.product.images[0]);
                  }

                  let itemTotal = null;
                  if (!regionInfo.isLoading) {
                    if (isQuotation) {
                      itemTotal = getQuotationItemPrice(wrappedItem.item, regionInfo.countryCode, wrappedItem.product);
                    } else {
                      itemTotal = getCartItemPrice(wrappedItem.item, wrappedItem.product, regionInfo.countryCode);
                    }
                  }

                  let itemDisabled;
                  if (isQuotation) {
                    itemDisabled = isQuotationItemDisabledInCart(wrappedItem.item, wrappedItem.product);
                  } else {
                    itemDisabled = wrappedItem.product.disabled;
                  }

                  const itemName = getWrappedItemName(wrappedItem);
                  const variantInfoProps = getWrappedItemVariantInfo(wrappedItem);

                  let quantitySelectProps;
                  if (wrappedItem.product) {
                    quantitySelectProps = {
                      minQuantity: wrappedItem.product.minQuantity,
                      maxQuantity: wrappedItem.product.maxQuantity
                    };
                  } else {
                    quantitySelectProps = {
                      minQuantity: 0,
                      maxQuantity: 0
                    };
                  }


                  const checkboxId = `cart-nav-item-${index}`
                  return <React.Fragment key={index}>
                    <div className="row position-relative">
                      <button
                        className={`btn btn-link text-primary py-0 pl-0 font-weight-bold ${styles.cartItem_remove}`}
                        onClick={handleRemoveItem(index)}
                        disabled={itemsLocked}
                      >
                        X
                      </button>
                      <div className="col-auto">
                        <label className="h-100 d-flex align-items-center" htmlFor={checkboxId}>
                          <input
                            id={checkboxId}
                            type="checkbox"
                            checked={checkState.getCheck(wrappedItem.isQuotation, wrappedItem.item._id)}
                            onChange={handleCartItemCheck(wrappedItem.isQuotation, wrappedItem.item._id)}
                            disabled={itemDisabled}
                          />
                        </label>
                      </div>
                      <div className="col-auto">
                        <div className={cartStyles.cartItem_imageContainer}>
                          <img
                            src={imageUrl}
                            alt="product image"
                          />
                        </div>
                      </div>
                      <div className={`col ${styles.cartItemRight}`}>
                        <div className="text-muted font-weight-medium text-uppercase">
                          {getWrappedItemSourceLabel(wrappedItem)}
                        </div>
                        <div className={classNames('font-weight-semibold mb-2', {
                          'text-muted': itemDisabled
                        })}>
                          {itemName}
                        </div>
                        {variantInfoProps && (
                          <VariantInfo
                            {...variantInfoProps}
                            onError={handleRemoveItem(index, false)}
                          />
                        )}

                        <CartQuantitySelect
                          value={wrappedItem.item.quantity}
                          onChange={handleSetQuantity(index)}
                          {...quantitySelectProps}
                          disabled={itemsLocked || itemDisabled}
                        />

                        <div className="font-weight-bold">
                          {itemTotal ? (
                            formatDinero(itemTotal)
                          ) : (
                            <Skeleton />
                          )}
                        </div>

                        {wrappedItem.isQuotation && (
                          <div>
                            <QuotationItemStatusBadgeComponent status={wrappedItem.item.status} />
                            {wrappedItem.item.status === QuotationItemStatuses.declined && (
                              <OverlayTrigger
                                placement="auto"
                                overlay={
                                  <Tooltip id={`tooltip-decline-reason-${wrappedItem.item._id}`}>
                                    {(wrappedItem.item as QuotationItem<QuotationItemStatuses.declined>).statusData.declineReason}
                                  </Tooltip>
                                }
                              >
                                <span className="ml-1 text-danger">
                                  <FiAlertCircle />
                                </span>
                              </OverlayTrigger>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr/>
                  </React.Fragment>
                })
              )}
              <div className="row mb-2">
                <div className="col">
                  Subtotal:
                </div>
                <div className="col-auto">
                  {subtotal}
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-md">
                  <Link href="/cart">
                    <a className={`${styles.btn} btn btn-dark text-uppercase w-100 h-100`}>
                      View & Edit Cart
                    </a>
                  </Link>
                </div>
                <div className="col-md">
                  <button className={`${styles.btn} btn btn-primary text-uppercase w-100 h-100`} onClick={handleCheckout} disabled={checkoutDisabled}>
                    Checkout
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      }}
    </Overlay>
  </Nav.Item>;
}