import React from 'react'
import PropTypes from 'prop-types'
import {Product} from "../types/models/product";

interface VariantInfoProps {
  variants: Product['variants'];
  variantPath?: string;
  onError?: () => void;
}

export default function VariantInfo({variants, variantPath, onError}: VariantInfoProps) {
  const [content, setContent] = React.useState<React.ReactNode | null>(null);

  const removeOnError = React.useCallback(() => {
    if (onError) {
      onError();
    }
  }, [onError]);

  React.useEffect(() => {
    if (!variantPath) {
      setContent(null);
      return;
    }

    const steps = variantPath.trim().split('/');

    if (steps.length !== variants.length) {
      setContent(null);
      return;
    }

    try {
      const variantChoices = []
      for (let i = 0; i < steps.length; i++) {
        const variant = variants[i];
        const step = parseInt(steps[i]);
        variantChoices.push(
          <div key={i}>
            <strong>{variant.name}</strong>:
            {` `}
            {variant.options[step].value}
          </div>
        )
      }
      setContent(variantChoices);
    } catch(e) {
      removeOnError();
    }
  }, [removeOnError, variants, variantPath]);

  return <React.Fragment>
    {content}
  </React.Fragment>;
}

VariantInfo.defaultProps = {
  indexToRemoveOnError: -1
};

VariantInfo.propTypes = {
  variants: PropTypes.array.isRequired,
  variantPath: PropTypes.string.isRequired,
}