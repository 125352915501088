import Link from "next/link";
import { AiOutlineMail } from "react-icons/ai";
import React from "react";
import classNames from "classnames";
import { useSelector } from "react-redux";
import styles from "../../styles/footer.module.scss";
import { RootState } from "../../store/reducers/reducers";

export default function Footer() {
  const signedIn = useSelector((state: RootState) => state.user.signedIn);

  const footerColClassName = classNames({
    'col-sm-4': signedIn,
    'col-sm-6': !signedIn,
  });

  return <div className={styles.footer}>
    <div className={`container ${styles.footerImage}`}>
      <div className={classNames('row', styles.navigations)}>
        <div className="col-md-3 mb-lg-0 mb-6">
          <img className="nav-logo" src="/images/footer-logo.svg" alt="logo" />
        </div>
        <div className="col-md-6">
          <div className="row">
            <div className={`${footerColClassName} mb-lg-0 mb-4`}>
              <div className={classNames('text-light', styles.navTitle)}>Information</div>
              
              <Link href="/how_we_4ward">
                <a className="text-light">How Indo4ward Works</a>
              </Link>
              <br />

              <Link href="/external_quotations">
                <a className="text-light">Quotations</a>
              </Link>
              <br />

              <Link href="/faq">
                <a className="text-light">FAQs</a>
              </Link>
              <br />

              <a className="text-light" href="https://tracking.indo4ward.com" target="_blank">Track your parcel</a>
              <br />
            </div>
            {signedIn && (
              <div className="col-sm-4 mb-lg-0 mb-6">
                <div className={classNames('text-light', styles.navTitle)}>Account</div>
                <Link href="/account/profile">
                  <a className="text-light">My Account</a>
                </Link>
                <br />
                <Link href="/account/orders">
                  <a className="text-light">My Orders</a>
                </Link>
                <br />
                <Link href="/account/wishlist">
                  <a className="text-light">My Wishlist</a>
                </Link>
                <br />
              </div>
            )}
            <div className={footerColClassName}>
              <div className={classNames('text-light', styles.navTitle)}>Company</div>
              <Link href="/pages/about_us"><a className="text-light">About Us</a></Link><br />
              <Link href="/pages/contact_us"><a className="text-light">Contact Us</a></Link><br />
              <a className="text-light" href="https://form.typeform.com/to/yFwjtwxW" target="_blank">Be a Partner</a><br />
              <a className="text-light" href="https://blog.indo4ward.com" target="_blank">Our Blog</a><br />
            </div>
          </div>
        </div>
        <div className="col-md-3">
          <div className="row mb-2">
            <div className="col-auto text-primary">
              <AiOutlineMail size="18px" />
            </div>
            <div className="col-auto d-flex flex-column align-items-end">
              <a href="mailto: info@indo4ward.com" className={`text-light ${styles.supportEmailText}`}>
                Support@Indo4ward
              </a>
              <br />
              <a href="mailto: dpo@indo4ward.com" className={`text-light ${styles.supportEmailText}`}>
                DPO@Indo4ward
              </a>
            </div>
          </div>

          <div id="social-icons">
            <a href="https://www.facebook.com/Indo4ward/">
              <img src="/images/facebook-icon.svg" />
            </a>
            <a href="https://www.linkedin.com/company/indo4ward">
              <img src="/images/linkedin-icon.svg" />
            </a>
            <a href="https://www.instagram.com/indo4ward/">
              <img src="/images/instagram-icon.svg" />
            </a>
            <a href="#" className="d-none">
              <img src="/images/youtube-icon.svg" />
            </a>
          </div>
        </div>
      </div>
    </div>

    <div className={styles.illustration}>
      <div className="container">
        <div className="row">
          <div className="col-sm-6 align-self-start py-2">
            Copyright © {new Date().getFullYear()} Indo4ward All Rights Reserved
          </div>
          <div className="col-sm-6 text-sm-right align-self-end pb-2">
            <Link href="/pages/terms_and_conditions"><a className="text-light">Terms & Conditions</a></Link>
            <span className="mx-2">|</span>
            <Link href="/pages/privacy_policy"><a className="text-light">Privacy Policy</a></Link>
          </div>
        </div>
      </div>
    </div>
  </div>
}