import React from "react";
import Link from 'next/link';
import Nav from 'react-bootstrap/Nav';
import useOnClickOutside from 'use-onclickoutside';
import classNames from 'classnames';
import {useWindowWidth} from "@react-hook/window-size";
import styles from '../styles/category_nav.module.scss';
import { useRegionLinkPrefix } from '../hooks/region';
import {CategoriesWrapper, WrappedCategory, WrappedCategoryMap} from "../service/category";

interface CategoryProps extends React.HTMLProps<HTMLDivElement> {
  childCategories: WrappedCategoryMap;
  x: number;
  y: number;
}

const Category = React.forwardRef<HTMLDivElement, CategoryProps>(({childCategories, x, y, ...others}: CategoryProps, ref) => {
  const [showIndex, setShowIndex] = React.useState(-1);
  const containerClassName = classNames('card d-md-block', styles.dropdownContainer);

  const containerStyle: React.CSSProperties = {};
  containerStyle.transform = `translate(${x}px, ${y}px)`;
  const menuEl = React.useRef(null);

  const regionLinkPrefix = useRegionLinkPrefix();

  const handleMouseEnter = React.useCallback((index) => () => {
    setShowIndex(index);
  }, []);

  const handleMouseExit = React.useCallback(() => {
    setShowIndex(-1);
  }, []);

  const renderChild = (childCategories: WrappedCategory[], index: number) => {
    const cat = childCategories[index];
    const grandchildCategories = cat.children;

    if (showIndex === index) {
      return <Category
        childCategories={grandchildCategories}
        x={200}
        y={0}
        ref={menuEl}
        onMouseLeave={handleMouseExit}
      />;
    }
    
    return null;
  }

  const childCategoryValues = Object.values(childCategories)
  return (
    <div className={containerClassName} style={containerStyle} ref={ref} {...others}>
      <div className={`${styles.dropdownBody} card-body`}>
        <nav className="nav flex-column">
          {childCategoryValues.map((cat, index) => {
            const grandchildCategories = cat.children

            if (Object.keys(grandchildCategories).length === 0) {
              return <Link
                key={index}
                href={`${regionLinkPrefix}/category/${cat.path}`}
              >
                <a className={styles.navLink}>
                  {cat.name}
                </a>
              </Link>
            } else {
              return <React.Fragment key={index}>
                <Link 
                  href={`${regionLinkPrefix}/category/${cat.path}`} 
                >
                  <a 
                    className={styles.navLink}
                    onMouseEnter={handleMouseEnter(index)}
                  >
                    {cat.name}
                  </a>
                </Link>
                {renderChild(childCategoryValues, index)}
              </React.Fragment>
            }
          })}
        </nav>
      </div>
    </div>
  )
})

interface CategoryNavProps {
  categories: CategoriesWrapper;
  className?: string;
}

export default function CategoryNav({categories, className}: CategoryNavProps) {
  const navItemEl = React.useRef<HTMLDivElement | null>(null);
  const [show, setShow] = React.useState(false);
  const [size, setSize] = React.useState<Record<"x" | "y", number>>({
    x: 0,
    y: 0
  });
  const toggleShow = React.useCallback((e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    if (show) return;

    setShow(!show);
  }, [show]);

  const hideSelf = React.useCallback(() => setShow(false), []);
  const container = React.useRef(null);
  const windowWidth = useWindowWidth({
    wait: 33
  });

  useOnClickOutside(container, hideSelf);
  React.useEffect(() => {
    if (!navItemEl.current) {
      return;
    }

    const rect = navItemEl.current.getBoundingClientRect();
    setSize({
      x: rect.x,
      y: rect.height + rect.y
    });
  }, [windowWidth]);

  const setNavItem = React.useCallback((el: HTMLDivElement) => {
    navItemEl.current = el;
    if (!el) {
      return;
    }

    const rect = navItemEl.current.getBoundingClientRect();
    setSize({
      x: rect.x,
      y: rect.height + rect.y
    });
  }, []);

  const {x, y} = size;

  const firstLevelCats = categories.categories;
  const combinedClassName = classNames('position-relative d-none d-md-block', className);
  return <div ref={container}>
    <Nav.Item ref={setNavItem} className={combinedClassName}>
      <a
        className="nav-link text-uppercase"
        onClick={toggleShow}
        href="#"
      >
        Categories
      </a>
    </Nav.Item>

    {show && (
      <Category
        childCategories={firstLevelCats}
        x={x}
        y={y}
      />
    )}
  </div>;
}