import React from 'react'
import PropTypes from 'prop-types'
import {AlertProps} from "react-bootstrap";
import styles from '../styles/timeout_alert.module.scss';
import classNames from "classnames";

interface TimeoutAlertProps {
	alertColor?: AlertProps['variant'];
	timeoutDuration?: number;
	onHide: () => void;
	message?: string | null;
	children?: React.ReactNode;
}

export default function TimeoutAlert({alertColor, timeoutDuration, onHide, message, children}: TimeoutAlertProps) {
	const displayMessage = React.useMemo(() => message || children, [message, children]);
	const containerObserverRef = React.useRef<IntersectionObserver | null>(null);
	const containerRef = React.useCallback((ref: HTMLDivElement) => {
		if (!ref) {
			return;
		}

		containerObserverRef.current?.disconnect();

		const observer = containerObserverRef.current = new IntersectionObserver(
			(entries) => {
				for (const e of entries) {
					e.target.toggleAttribute("data-stuck", !e.isIntersecting);
				}
			},
			{
				root: null,
				threshold: [0, 1],
				rootMargin: '-82px 0px 0px 0px'
			}
		);
		observer.observe(ref);
	}, []);

	React.useEffect(() => {
		if (!displayMessage || timeoutDuration === undefined || timeoutDuration <= 0) {
			return
		}
		const timeoutId = setTimeout(onHide, timeoutDuration);

		return () => {
			clearTimeout(timeoutId);

		};
	}, [displayMessage, onHide]);

	React.useEffect(() => {
		return () => {
			containerObserverRef.current?.disconnect();
		};
	}, []);

	const className = classNames(
		'alert',
		`alert-${alertColor}`,
		styles.sticky
	);

	return <>
		{displayMessage && (
			<div className={className} role="alert" ref={containerRef}>
				{displayMessage}
			</div>
		)}
	</>
}

TimeoutAlert.defaultProps = {
	alertColor: 'danger',
	timeoutDuration: 5000,
}

TimeoutAlert.propTypes = {
	alertColor: PropTypes.string,
	timeoutDuration: PropTypes.number,
	onHide: PropTypes.func.isRequired,
	message: PropTypes.string
}