import React from 'react'
import { useRouter } from 'next/router'
import { useSelector, useDispatch } from 'react-redux'
import urljoin from 'url-join'
import { actions } from '../store/actions'
import { replaceAll } from '../utils/string'
import AuthService from "../service/auth";
import {RootState} from "../store/reducers/reducers";

const LAST_CHOSEN_REGION_KEY = 'indo4ward:last_chosen_region';

export interface RegionInfo {
	isLoading: boolean;
	countryCode: string;
}

export function useRegionCountryCode(): RegionInfo {
	const router = useRouter()
	const signedIn = AuthService.useIsLoggedIn()
	const tfaPassed = AuthService.useHasCompletedTfa()

	const { query } = router
	const routerCountryCode = Array.isArray(query.countryCode) ? query.countryCode[0] : query.countryCode;

	const state = useSelector((state: RootState) => state.region)
	const {
		defaultRegion,
		defaultRegionLoaded,
		preferredRegion,
		preferredRegionLoaded
	} = state;
	let {lastChosenRegion} = state;

	const dispatch = useDispatch();

	if (!defaultRegionLoaded) {
		// load default region
		dispatch(actions.region.fetchDefaultRegion());
	}

	if (!preferredRegionLoaded && signedIn && tfaPassed) {
		dispatch(actions.region.fetchPreferredRegion());
	}

	const isClient = typeof window !== 'undefined'
	if (isClient && !lastChosenRegion) {
		lastChosenRegion = window.sessionStorage.getItem(LAST_CHOSEN_REGION_KEY);
	}

	const chosenRegion = routerCountryCode || preferredRegion || lastChosenRegion || defaultRegion || '';

	const isLoading = !chosenRegion;
	if (isLoading) {
		return {
			isLoading: true,
			countryCode: ''
		};
	} else {
		const countryCode = chosenRegion.toUpperCase()
		if (isClient) {
			window.sessionStorage.setItem(LAST_CHOSEN_REGION_KEY, countryCode)
		}
		return {
			countryCode: chosenRegion.toUpperCase(),
			isLoading: false
		};
	}
}

export function useSetPreferredRegion() {
	const dispatch = useDispatch()
	return (newCurrentRegion: string) => dispatch(actions.region.setPreferredRegion(newCurrentRegion))
}

export function useLinksWithRegion(links: string[]) {
	const regionInfo = useRegionCountryCode()
	if (regionInfo.isLoading) {
		return links
	}

	return links.map(link => {
		return urljoin('/', regionInfo.countryCode, link)
	})
}

export function useRegionLinkPrefix() {
	const regionInfo = useRegionCountryCode()
	if (regionInfo.isLoading) {
		return ''
	}

	return '/' + regionInfo.countryCode
}

export function useRegionLinkSubber() {
	const regionLinkPrefix = useRegionLinkPrefix()

	return React.useCallback((urlString) => {
		// const isAbs = urlString.indexOf('http://') === 0 || urlString.indexOf('https://') === 0
		// if (!isAbs) {
		// 	const isRootPath = urlString.indexOf('/') === 0
		// 	if (!isRootPath) {
		// 		return urlString
		// 	}
		// 	return regionLinkPrefix + urlString
		// }

		const countryCode = regionLinkPrefix.substring(1)
		return replaceAll(urlString, '${country}', countryCode)
	}, [regionLinkPrefix])
}