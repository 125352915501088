import Head from "next/head";
import React from "react";

interface LayoutBaseProps {
  children: React.ReactNode;
}

export default function LayoutBase({children}: LayoutBaseProps) {
  return <>
    <Head>
      <title>Indo4ward</title>
      <meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no" />
      <link href="https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700;800;900&display=swap" rel="stylesheet"/>
    </Head>

    {children}
  </>
}