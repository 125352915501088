import React from 'react'
import Link from 'next/link'
import Nav from 'react-bootstrap/Nav'
import Overlay from 'react-overlays/Overlay'
import {AiFillBell} from 'react-icons/ai'
import {extractAxiosError} from '../utils/error'
import Api from '../service/api'
import styles from '../styles/notifications_nav.module.scss'
import {getNotificationImageSrc, handleNotificationClick} from '../lib/notification'
import {NotificationGroup} from "../types/models/notification";

export default function NotificationNav() {
  const [show, setShow] = React.useState(false);
  const [notifications, setNotifications] = React.useState<NotificationGroup[]>([]);

  const containerRef = React.useRef(null);
  const triggerRef = React.useRef(null);
  const overlayTimeout = React.useRef<number | null>();
  const isMounted = React.useRef(false);

  React.useEffect(() => {
    isMounted.current = true
    Api.notifications.getAll()
      .then(res => {
        setNotifications(res.data.notifications)
      })
      .catch(err => {
        console.error(extractAxiosError(err))
      })

    const intervalId = setInterval(() => {
      if (isMounted.current && overlayTimeout.current && Date.now() > overlayTimeout.current) {
        setShow(false)
      }
    }, 75)

    return () => {
      isMounted.current = false
      clearInterval(intervalId)
    }
  }, [])

  const handleCloseOverlay = React.useCallback(() => {
    setShow(false)
  }, []);

  const handleMouseEnter = React.useCallback(() => {
    setShow(true)
    overlayTimeout.current = null
  }, []);

  const handleMouseLeave = React.useCallback(() => {
    overlayTimeout.current = Date.now() + 150
  }, []);

  return <Nav.Item className="mr-2 d-none d-md-block" ref={containerRef}>
    <Link href="/account/notifications">
      <a 
        className="nav-link"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        ref={triggerRef}
      >
        <AiFillBell size={24} />
      </a>
    </Link>

    <Overlay
      show={show}
      rootClose
      onHide={handleCloseOverlay}
      offset={[0, 10]}
      placement="bottom"
      container={containerRef}
      target={triggerRef}
    >
      {({ props }) => {
        return <div {...props} className={styles.card_container} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
          <div className={`card ${styles.card}`}>
            <div className={`card-header font-weight-bold ${styles.card_header}`}>
              Notifications
            </div>

            <div className="card-body p-0">
              {notifications.length === 0 ? (
                <p>No Notifications.</p>
              ) : (
                notifications.map((n, index) => {
                  const notification = n.notifications[0];
                  const imageSrc = getNotificationImageSrc(notification);
                  return (
                    <div key={index} className={`media py-2 px-4 ${styles.notificationItem}`} onClick={handleNotificationClick(notification)}>
                      <img src={imageSrc} className={styles.icon} />
                      <div className="media-body">
                        <div className="font-weight-semibold">{notification.title}</div>
                        <div>{notification.description}</div>
                      </div>
                    </div>
                  )
                })
              )}
            </div>

            <div className="card-footer text-primary text-center">
              <Link href="/account/notifications">
                <a>
                  View All
                </a>
              </Link>
            </div>
          </div>
        </div>
      }}
    </Overlay>
  </Nav.Item>
}