import React from 'react';
import TopNav from './top_nav';
import Footer from "./footer";
import LayoutBase from "./layout_base";

interface LayoutFluidProps {
  children: React.ReactNode;
}

export default function LayoutFluid({ children }: LayoutFluidProps) {
  return <LayoutBase>
    <TopNav />
    
    <main>{children}</main>

    <Footer />
  </LayoutBase>
}