import React from "react";
import { CgMathPlus, CgMathMinus } from 'react-icons/cg';
import { isPositiveInteger } from '../../utils/string';
import styles from '../../styles/cart_quantity_select.module.scss';
import TimeoutAlert from '../timeout_alert';

interface CartQuantitySelectProps extends Omit<React.HTMLAttributes<HTMLInputElement>, 'value' | 'onChange'> {
  maxQuantity: number;
  minQuantity: number;
  onChange: (value: number) => void;
  disabled?: boolean;
  value: number;
}

function CartQuantitySelect({ maxQuantity, minQuantity, onChange, value, disabled, ...others }: CartQuantitySelectProps) {
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null)
  const [quantity, setQuantity] = React.useState<string>(value.toString())

  React.useEffect(() => {
    if (value) {
      setQuantity(value.toString())
    }
  }, [value])

  const checkQuantityForError = React.useCallback((qty: number) => {
    let error = null
    if (maxQuantity !== 0) {
      if (qty > maxQuantity) {
        error = 'Max quantity allowed: ' + maxQuantity
      }
    }

    if (qty < minQuantity) {
      error = 'Minimum quantity allowed: ' + minQuantity
    }

    return error;
  }, [maxQuantity, minQuantity])

  const handleQuantityChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isPositiveInteger(e.target.value)) {
      return;
    }


    if (e.target.value.length > 4) {
      setErrorMessage('Quantity is too long');
    }

    const qty = parseInt(e.target.value);
    const error = checkQuantityForError(qty);
    if (error) {
      setErrorMessage(error);
      return;
    }
    setQuantity(e.target.value);
  }, [checkQuantityForError])

  const handleBlur = React.useCallback(() => {
    onChange(parseInt(quantity));
    setQuantity(value.toString());
  }, [onChange, value, quantity])

  const handleSubmit = React.useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();

    handleBlur();
  }, [handleBlur]);

  const handleQuantityInc = React.useCallback(() => {
    const error = checkQuantityForError(value + 1);
    if (error) {
      setErrorMessage(error);
      return;
    }

    try {
      onChange(value + 1)
    } catch (err) {}
  }, [value, checkQuantityForError])

  const handleQuantityDec = React.useCallback(() => {
    const error = checkQuantityForError(value - 1);
    if (error) {
      setErrorMessage(error);
      return;
    }

    try {
      const qty = value - 1
      if (qty >= 1)  {
        onChange(qty)
      }
    } catch (err) {}
  }, [value, checkQuantityForError])

  return <div>
    <div className={styles.button_container}>
      <button className={styles.quantityDec} onClick={handleQuantityDec} disabled={disabled}>
        <CgMathMinus />
      </button>
    </div>
    <form onSubmit={handleSubmit} className="d-inline">
      <input
        type="number"
        value={quantity}
        onChange={handleQuantityChange}
        onBlur={handleBlur}
        className={`form-control ${styles.quantityInput}`}
        disabled={disabled}
        maxLength={4}
        {...others}
      />
    </form>
    <div className={styles.button_container}>
      <button className={styles.quantityInc} onClick={handleQuantityInc} disabled={disabled}>
        <CgMathPlus />
      </button>
    </div>

    <TimeoutAlert
      message={errorMessage}
      onHide={() => setErrorMessage(null)}
    />
  </div>
}

export default CartQuantitySelect